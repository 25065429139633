import React, { useEffect, useState } from 'react';
import './MarketplaceTemplatePreview.component.scss';
import Helpers from '../../../utils/helper';
import GanttChart from '../../GanttChartComponent/GanttChart';
import { DataGenerator } from './DataGenerator';
import { GanttChartItem } from '../../GanttChartComponent/models/GanttChartItem.model';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import { TimelineStage } from '../Model/TimelineStage';
import { TimelineEvent } from '../Model/TimelineEvent';
import Layout from '../../../components/Layout';
import { useDispatch } from 'react-redux';
import { GetTimelineStages } from '../../../slices/marketplace/thunks/get-timeline-stages.thunk';
interface MarketplaceTemplatePreviewProps {
    timelineTemplateId?: number;
    events?: TimelineEvent[];
}
export const MarketplaceTemplatePreviewComponent = (
    props: MarketplaceTemplatePreviewProps
) => {
    const dispatch = useDispatch() as any;
    const timelineStages = useStateSelector(
            (state) => state.timelines.timelineStages
        );
    const [isLoading, setIsLoading] = useState(true);
    const config = {
        header: {
            month: {
                dateFormat: 'MMMM  YYYY',
                style: {
                    background: 'linear-gradient( grey, black)',
                    textShadow: '0.5px 0.5px black',
                    fontSize: 12,
                },
            },
            dayOfWeek: {
                style: {
                    background: 'linear-gradient( orange, grey)',
                    fontSize: 9,
                },
            },
            dayTime: {
                style: {
                    background: 'linear-gradient( grey, black)',
                    fontSize: 9,
                    color: 'orange',
                },
                selectedStyle: {
                    background: 'linear-gradient( #d011dd ,#d011dd)',
                    fontWeight: 'bold',
                    color: 'white',
                },
            },
        },
        taskList: {
            title: {
                label: 'Task Todo',
                style: {
                    background: 'linear-gradient( grey, black)',
                },
            },
            task: {
                style: {
                    backgroundColor: 'grey',
                    color: 'white',
                },
            },
            verticalSeparator: {
                style: {
                    backgroundColor: '#fbf9f9',
                },
                grip: {
                    style: {
                        backgroundColor: 'red',
                    },
                },
            },
        },
        dataViewPort: {
            rows: {
                style: {
                    backgroundColor: 'white',
                },
            },
            task: {
                showLabel: true,
                style: {
                    borderRadius: '0px',
                    boxShadow: 'none',
                },
            },
        },
    };
    const [generator] = useState(new DataGenerator());
    const [data, setData] = useState<GanttChartItem[]>([]);
    const [state, setState] = useState<{
        itemheight: number;
        daysWidth: number;
        startDate: Date;
        endDate: Date;
    }>();
    const axios = useStateSelector((a) => a.core.axios);

    useEffect(() => {
        const loadData = async () => {
            let events: TimelineEvent[] = [];
            if (props.timelineTemplateId) {
                const templateResponse = await axios.get(
                    `/api/Marketplace/TimelineTemplates/${props.timelineTemplateId}`
                );
                if (templateResponse.status !== 200) {
                    createNotification(
                        'Unexpected error occurred while loading Timeline Events.',
                        'error'
                    );
                    return;
                }
                events = templateResponse.data.events;
            } else {
                events = props.events;
            }
            dispatch(GetTimelineStages());
            const stages: TimelineStage[] = timelineStages;

            const lastEventDay = events.reduce(
                (max, event) => Math.max(max, event.dayEnd),
                0
            );

            const data = generator.generateData(stages, events);
            setData(data);

            const endDate = new Date();
            endDate.setDate(endDate.getDate() + lastEventDay);
            setState({
                itemheight: 30,
                daysWidth: 0,
                startDate: new Date(),
                endDate: endDate,
            });
            setIsLoading(false);
        };

        loadData().then();
    }, []);

    return (
        <div className="associate-members-table mt-3">
            <div className="marketplace-template-preview">
                {isLoading ? (
                    Helpers.renderTableLoader()
                ) : (
                    <div className="without_title_content">
                        {data && data.length > 0 ? (
                            <GanttChart
                                config={config}
                                data={data}
                                itemheight={state.itemheight}
                                startDate={state.startDate}
                                endDate={state.endDate}
                            />
                        ) : (
                            <h2 className="no-page-access-alert" style={{padding: '0 21px 30px' }}>
                                No events available to display
                            </h2>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
