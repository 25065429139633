import { createAsyncThunk } from '@reduxjs/toolkit';
import { TableFilter } from '../../../components/Table/models/table-filter';
import { objectToQueryString } from '../../../utils/queryHelper';
import createNotification from '../../../utils/createNotification';

export const GetTimelines = createAsyncThunk(
    'timelineTemplates/getTimelineTemplates',
    async (filterParams: TableFilter, dispatch: any) => {
        const axios = dispatch.getState().core.axios; 
        const queryString = objectToQueryString(filterParams);

        try {
            const response = await axios.get(`/api/Marketplace/TimelineTemplates?${queryString}`);

            if (response.status !== 200) {
                createNotification(
                    'Could not fetch Timelines at the moment.',
                    'error'
                );
                throw new Error('Failed to fetch Timeline Templates');
            }

            return {
                timelineTemplates: response.data.list,
                pageNumber: response.data.pageNumber,
                totalItems: response.data.totalItems,
                pageSize: response.data.pageSize,
            };
        } catch (error) {
            createNotification(
                'An error occurred while fetching Timeline Templates.',
                'error'
            );
            throw error;
        }
    }
);
