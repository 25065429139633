import { createSlice } from '@reduxjs/toolkit';
import { GetTimelines } from './thunks/get-timelines.thunk';
import { GetTimelineStages } from './thunks/get-timeline-stages.thunk';
import { TimelinesState } from './timeline.state';

const initialState: TimelinesState = {
    timelinesTable: {
        timelineTemplates: [],
        pageNumber: 0,
        totalItems: 0,
        pageSize: 0,
    },
    timelineStages: [],
    isLoading: false,
    status: 'succeeded',
    error: '',
};

const timelinesSlice = createSlice({
    name: 'timelines',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(GetTimelines.pending, (state) => {
                setPendingState(state);
            })
            .addCase(GetTimelines.rejected, (state) => {
                setRejectedState(state);
            })
            .addCase(GetTimelines.fulfilled, (state, action) => {
                const { timelineTemplates, pageNumber, totalItems, pageSize } =
                    action.payload;
                state.timelinesTable.timelineTemplates = timelineTemplates;
                state.timelinesTable.pageNumber = pageNumber;
                state.timelinesTable.totalItems = totalItems;
                state.timelinesTable.pageSize = pageSize;
                setSucceededState(state);
            })
            .addCase(GetTimelineStages.pending, (state) => {
                setPendingState(state);
            })
            .addCase(GetTimelineStages.rejected, (state) => {
                setRejectedState(state);
            })
            .addCase(GetTimelineStages.fulfilled, (state, action) => {
                state.timelineStages = action.payload;
                setSucceededState(state);
            });
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

export const {} = timelinesSlice.actions;
export default timelinesSlice.reducer;