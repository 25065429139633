import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import Loader from 'react-loaders';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { HomeButton } from '../../components/HomeButton/home-button';
import { useStateSelector } from '../../store/selectors';
import { USER_ROLES } from '../../utils/constants';
import TimelineTemplates from './MarketplaceTabs/TimelineTemplates/TimelineTemplates';
import AdminDashboard from './MarketplaceTabs/AdminDashboard/AdminDashboard';
import MarketplaceSettings from './MarketplaceTabs/MarketplaceSettings/MarketplaceSettings';


const tabComponents = [
    // { id: 0, content: <AdminDashboard/> },
    { id: 0, content: <TimelineTemplates/> },
    // { id: 2, content: <MarketplaceSettings/> },
];

const AdminMarketplace = () => {

    const userSummary = useStateSelector((state) => state.userSummary.summary);
    const isAdmin = userSummary.roleId === USER_ROLES.ADMIN;

    const [activeTab, setActiveTab] = useState(0);
    const [tabData] = useState([
        // 'Marketplace Administration Dashboard',
        'Timeline Templates',
        // 'Marketplace Settings',
        // 'Document Center',
        // 'Billing Summary',
        // 'Marketplace Audit Log',
    ]);

    const [isLoadingMarketplaceData, setIsLoadingMarketplaceData] = useState(false);

    const renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '80vw', height: '80vh' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader
                            active={isLoadingMarketplaceData}
                            type="ball-scale-multiple"
                        />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    function renderTabContent() {
        const activeTabData = tabComponents.find(tab => tab.id === activeTab);
        return (activeTabData?.content) || <div className="d-flex align-items-center justify-content-center mt-4"><h1>Empty Tab</h1></div>;
    }

    const renderTabs = () => {
        return (
            <Card className="main-card">
                <div className="body_white_box practice-page-tabs m-0">
                    <div className="body_inner_content">
                        <div className="RRT__container">
                            <div className="RRT__tabs body-tabs">
                                    {tabData?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    activeTab === index
                                                        ? 'RRT__tab RRT__tab--first RRT__tab--selected'
                                                        : 'RRT__tab'
                                                }
                                                onClick={() => {
                                                    setActiveTab(index);
                                                }}>
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {renderTabContent()}
                        </div>
                    </div>
            </Card>
        )
    }

    const renderMarketplacePage = () => {
        return (
            <div>
                <div>
                    <div className="main-title-content">
                        <div className="title-breadcrumb">
                            <div className="page-title">
                                <h3>Marketplace Admin</h3>
                            </div>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <HomeButton />
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    Marketplace Admin Home
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                
                    {renderTabs()}
            </div>
        )
    }

    return (
        <Layout>
            {isAdmin ? (
                renderMarketplacePage()
            ) : (
                <h1>You don't have an access to see this page.</h1>
            )}
        </Layout>
    );
};

export default AdminMarketplace;