import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Field, Form, Input } from '@availity/form';
import Loader from 'react-loaders';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { useStateSelector } from '../../../store/selectors';
import { USER_ROLES } from '../../../utils/constants';
import { Col, Label, Row } from 'reactstrap';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faExclamationCircle,
    faGear,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { HomeButton } from '../../../components/HomeButton/home-button';
import './TimelineEditor.scss';
import * as yup from 'yup';
import TimelineTable from './TimelineTable';
import { TimelineEvent } from '../Model/TimelineEvent';
import { TimelineTemplate } from '../Model/TimelineTemplate';
import { PARTY_ITEMS } from '../Constants/dropdownItems';
import { MAX_DEPENDENCIES_AMOUNT } from '../Constants/constants';
import createNotification from '../../../utils/createNotification';
import EditableName from './EditibleName';
import { TimelineStage } from '../Model/TimelineStage';
import StageEditorModal from './StageEditorModal/StageEditorModal';
import { MarketplaceTemplatePreviewComponent } from '../MarketplaceTemplatePreview/MarketplaceTemplatePreview.component';
import { TimelineParty } from '../Model/TimelineParty';
import { useParams } from 'react-router-dom';
import Helpers from '../../../utils/helper';
import { FieldArray } from 'formik';
import { TimelineEventSortingService } from './TimelineEventSortingService';
import { TimelineEventDependency } from '../Model/TimelineEventDependency';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import { useDispatch } from 'react-redux';
import { GetTimelineStages } from '../../../slices/marketplace/thunks/get-timeline-stages.thunk';

const TimelineEditor = () => {
    const dispatch = useDispatch() as any;
    const userSummary = useStateSelector((state) => state.userSummary.summary);
    const axios = useStateSelector((state) => state.core.axios);
    const timelineStages = useStateSelector(
        (state) => state.timelines.timelineStages
    );
    const navigate = useNavigate();

    const templateId = useParams().id;
    const newTemplate = templateId == 'new';

    const isAdmin = userSummary.roleId === USER_ROLES.ADMIN;

    const [isLoading, setIsLoading] = useState(false);

    const [events, setEvents] = useState<TimelineEvent[]>([]);
    const [initialEvents, setInitialEvents] = useState<TimelineEvent[]>([]);

    const [stagesOrder, setStagesOrder] = useState<number[]>([]);

    const [templateVersion, setTemplateVersion] = useState<number[]>(null);

    const defaultTimelineName = 'Timeline Template';
    const [timelineName, setTimelineName] = useState('');
    const [initialTimelineName, setInitialTimelineName] = useState('');

    const [nameIsValid, setNameIsValid] = useState(!newTemplate);
    const [nameIsEditable, setNameIsEditable] = useState(newTemplate);

    const [stages, setStages] = useState<TimelineStage[]>([]);
    const [stagesAreLoading, setStagesAreLoading] = useState(false);
    const [selectedStage, setSelectedStage] = useState(0);

    const [isStageEditorModalVisible, setIsStageEditorModalVisible] =
        useState(false);
    const [flagToFetchStages, setFlagToFetchStages] = useState(false);

    const [previewIsVisible, setPreviewIsVisible] = useState(false);

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [initialDataLoading, setInitialDataLoading] = useState(false);

    const areEventsEqual = (a: TimelineEvent[], b: TimelineEvent[]) => {
        if (a.length !== b.length) return false;
        return a.every((event, index) => {
            const otherEvent = b[index];
            return JSON.stringify(event) === JSON.stringify(otherEvent);
        });
    };

    const fetchTimelineTemplate = (id: number) => {
        setIsLoading(true);

        axios
            .get(`api/Marketplace/TimelineTemplates/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    const template: TimelineTemplate = response.data;
                    setInitialTimelineName(template.name);
                    setTimelineName(template.name);
                    setTemplateVersion(template.version);
                    const mappedEvents = recalculateRows(
                        template.events.map(mapEvent)
                    );
                    const stagesOrder = getStagesOrder(mappedEvents);
                    setStagesOrder(stagesOrder);
                    setEvents(mappedEvents);
                    setInitialEvents(mappedEvents);
                } else {
                    createNotification(
                        'There was an error while loading Timeline Template.',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'There was an error while loading Timeline Template.',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (templateId) {
            if (newTemplate) {
                setTimelineName(defaultTimelineName);
            } else if ((Number(templateId) || 0) > 0) {
                fetchTimelineTemplate(Number(templateId));
            } else {
                createNotification(
                    'There was an error while loading Timeline Template.',
                    'error'
                );
            }
        }
    }, []);

    const fetchStages = () => {
            setStagesAreLoading(true);
            dispatch(GetTimelineStages()).then(() => {
                setStagesAreLoading(false);
            });
        };

    useEffect(() => {
        fetchStages();
    }, []);

    useEffect(() => {
        fetchStages();
    }, [flagToFetchStages]);

    useEffect(() => {
            setStages(timelineStages);
        }, [fetchStages]);

    useEffect(() => {
        const nameChanged = timelineName !== initialTimelineName;
        const nameIsNotEmpty = timelineName.length > 0;

        const nameCanBeSaved =
            (nameChanged || !newTemplate) &&
            nameIsNotEmpty &&
            nameIsValid &&
            !nameIsEditable;

        const shouldSetBeEnabled =
            nameCanBeSaved &&
            (nameChanged ||
                newTemplate ||
                !areEventsEqual(events, initialEvents));

        setIsSaveEnabled(shouldSetBeEnabled);
    }, [nameIsValid, nameIsEditable, events]);

    const initialFormValues = {
        eventName: '',
        stage: '',
        party: '',
        duration: '',
        dependencies: [{ dependencyEventId: '', offsetDays: 0 }],
    };

    const validationSchema = yup.object().shape({
        eventName: yup.string().trim().required(),
        stage: yup.string().trim().required(),
        party: yup.string().trim().required(),
        duration: yup.number().required().min(0, 'min 0'),
    });

    const doesNameExist = async (newName: string): Promise<boolean> => {
        try {
            const response = await axios.get<boolean>(
                `api/Marketplace/TimelineTemplates/DoesExist/${encodeURIComponent(
                    newName
                )}`
            );

            if (!response.data) {
                setTimelineName(newName);
                setNameIsValid(true);
                return false;
            } else {
                createNotification(
                    'A timeline with this name already exists.',
                    'error'
                );
                return true;
            }
        } catch (error) {
            createNotification(
                'An error occurred while checking the name. Please try again later.',
                'error'
            );
            return true;
        }
    };

    const axiosRequest = (template: any) =>
        newTemplate
            ? axios.post('/api/Marketplace/TimelineTemplates', template)
            : axios.put(
                  `/api/Marketplace/TimelineTemplates/${templateId}`,
                  template
              );

    const handleSave = () => {
        setIsLoading(true);

        const timelineTemplate = {
            name: timelineName,
            events: events.map((event) => ({
                ...event,
            })),
            version: templateVersion,
        };

        axiosRequest(timelineTemplate)
            .then((response: any) => {
                if (response && response.status === 200) {
                    createNotification(
                        'Template successfully saved.',
                        'success'
                    );
                    if (Number(response.data) > 0) {
                        if (newTemplate) {
                            navigate(
                                `/marketplace/admin/templates/${response.data}`
                            );
                            fetchTimelineTemplate(Number(response.data));
                        }
                    } else {
                        fetchTimelineTemplate(Number(templateId));
                    }
                    setIsSaveEnabled(false);
                } else {
                    const message = response.response.data.title;
                    createNotification(message || '', 'error');
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapEvent = (data: {
        id: string;
        stage: number;
        name: string;
        party: number;
        duration: number;
        dependencies: TimelineEventDependency[];
        dayStart: number;
        dayEnd: number;
    }) => {
        const result: TimelineEvent = {
            id: data.id ?? Helpers.generateGUID(),
            stage: data.stage,
            name: data.name || 'Default Event',
            party: data.party || 0,
            dependencies: data.dependencies?.filter(
                (d: TimelineEventDependency) => d.dependencyEventId !== ''
            ),
            duration: data.duration || 0,
            dayStart: data.dayStart,
            dayEnd: data.dayEnd,
            linkedAction: 'Add Action',
        };
        return result;
    };

    const handleFlagState = () => {
        setFlagToFetchStages(!flagToFetchStages);
    };

    const recalculateRows = (rows: TimelineEvent[]): TimelineEvent[] => {
        rows = TimelineEventSortingService.sortTimelineEvents(rows);
        return rows;
    };

    const getStagesOrder = (rows: TimelineEvent[]): number[] => {
        const order = TimelineEventSortingService.getDistinctStages(rows);
        return order;
    };

    const handleAddRow = (data: any) => {
        const newEvent = mapEvent(data);
        const updatedRows = TimelineEventSortingService.placeUpdatedEvent(
            [...events],
            newEvent
        );
        try {
            const sortedEvents = recalculateRows([...updatedRows]);
            const newStagesOrder = getStagesOrder(sortedEvents);

            setEvents(sortedEvents);
            setStagesOrder(newStagesOrder);
            setSelectedStage(0);
        } catch {
            createNotification(
                'Invalid dependencies and dependent combination.',
                'error'
            );
        }
    };

    const handleRemoveRow = (id: string) => {
        let updatedRows = events.filter((row) => row.id !== id);
        updatedRows = updatedRows.map((row) => ({
            ...row,
            dependencies: row.dependencies.filter(
                (dependency) => dependency.dependencyEventId !== id
            ),
        }));

        try {
            const recalculatedRows = recalculateRows(updatedRows);
            const newStagesOrder = getStagesOrder(recalculatedRows);
            setEvents(recalculatedRows);
            setStagesOrder(newStagesOrder);
        } catch {
            createNotification(
                'Invalid dependencies and dependent combination.',
                'error'
            );
        }
    };

    const handleUpdateRow = (
        id: string,
        updatedValues: {
            name?: string;
            duration?: number;
            stage?: number;
            party?: TimelineParty;
            dependencies?: TimelineEventDependency[];
        }
    ) => {
        const rowIndex = events.findIndex((row) => row.id === id);
        if (rowIndex === -1) return;

        const updatedRow = {
            ...events[rowIndex],
            ...updatedValues,
        };

        const isDuplicate = events.some(
            (row, index) =>
                index !== rowIndex &&
                row.name === updatedRow.name &&
                row.stage === updatedRow.stage &&
                row.party === updatedRow.party
        );
        if (isDuplicate) {
            createNotification(
                "The combination of 'Event', 'Stage', and 'Party' must be unique.",
                'error'
            );
            return;
        }

        if (
            updatedValues.dependencies &&
            updatedValues.dependencies.length > 0
        ) {
            updatedRow.dependencies = updatedValues.dependencies.map(
                (dependency) => ({
                    ...dependency,
                })
            );

            const nonexistentDependencies = updatedRow.dependencies.filter(
                (dep) =>
                    !events.some((event) => event.id === dep.dependencyEventId)
            );
            if (nonexistentDependencies.length > 0) {
                createNotification(
                    'Some dependencies reference non-existent events.',
                    'error'
                );
                return;
            }

            const rowStageNextIndex = stagesOrder.indexOf(updatedRow.stage) + 1;
            const followingStages =
                rowStageNextIndex == stagesOrder.length
                    ? []
                    : stagesOrder.slice(rowStageNextIndex);
            const dependenciesFromNextStages =
                followingStages.length == 0
                    ? []
                    : updatedRow.dependencies.filter((dep) =>
                          followingStages.includes(
                              events.find(
                                  (event) => event.id === dep.dependencyEventId
                              )?.stage
                          )
                      );

            const dependentEvents = events.reduce((arr, event) => {
                if (
                    event.dependencies &&
                    event.dependencies.length > 0 &&
                    event.dependencies.some(
                        (d) =>
                            d.dependencyEventId == updatedRow.id &&
                            event.stage != updatedRow.stage
                    )
                ) {
                    arr.push(event);
                }
                return arr;
            }, [] as TimelineEvent[]);

            if (
                dependenciesFromNextStages.length > 0 &&
                dependentEvents.length > 0
            ) {
                createNotification(
                    'Invalid dependencies and dependent combination.',
                    'error'
                );
                return;
            }
        }

        let updatedRows = [...events];
        updatedRows[rowIndex] = { ...updatedRow };

        updatedRows = TimelineEventSortingService.placeUpdatedEvent(
            updatedRows,
            updatedRow
        );
        try {
            const recalculatedRows = recalculateRows(updatedRows);
            const newStagesOrder = getStagesOrder(recalculatedRows);
            setEvents(recalculatedRows);
            setStagesOrder(newStagesOrder);
        } catch {
            createNotification(
                'Invalid dependencies and dependent combination.',
                'error'
            );
        }
    };

    const renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '80vw', height: '40vh' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading events...</h6>
                </div>
            </div>
        );
    };

    const renderDependencyRow = (
        dependency: TimelineEventDependency,
        usedDependencies: TimelineEventDependency[],
        index: number,
        count: number,
        remove: () => void,
        addNew: () => void
    ) => {
        const availableDependencies = events.filter((e) =>
            usedDependencies.every(
                (ud) =>
                    ud.dependencyEventId !== e.id ||
                    dependency.dependencyEventId === e.id
            )
        );

        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={6}>
                            <label>Dependencies</label>
                            <div className="form-group">
                                <Input
                                    className="input-height"
                                    type="select"
                                    onChange={(event) => {
                                        const selectedValue =
                                            event?.target.value;
                                        dependency.dependencyEventId =
                                            selectedValue;
                                    }}
                                    label="Dependencies"
                                    name={`dependencies.${index}.dependencyEventId`}
                                    placeholder="Select">
                                    {[
                                        {
                                            id: '',
                                            name: 'Select',
                                        },
                                        ...availableDependencies,
                                    ].map((item) => (
                                        <option
                                            value={item.id}
                                            key={'dependency' + item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Label>Offset (days)</Label>
                            <Field
                                className="input-height"
                                type="number"
                                min={0}
                                name={`dependencies.${index}.offsetDays`}
                                id="offset"
                                disabled={!dependency.dependencyEventId}
                                validate={(value) => {
                                    if (!dependency.dependencyEventId) {
                                        return undefined;
                                    }
                                    if (value < 0) {
                                        return 'The minimum value must be 0';
                                    }
                                    return undefined;
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <label className="invisible hide-on-small">
                                text
                            </label>
                            <div className="d-flex justify-content-around mt-md-2 change-on-small">
                                <FontAwesomeIcon
                                    className={`cursor-pointer ${
                                        availableDependencies.length > 0 &&
                                        count < MAX_DEPENDENCIES_AMOUNT &&
                                        count < availableDependencies.length
                                            ? ''
                                            : 'text-muted'
                                    }`}
                                    onClick={() => {
                                        if (
                                            availableDependencies.length > 0 &&
                                            count < MAX_DEPENDENCIES_AMOUNT &&
                                            count < availableDependencies.length
                                        ) {
                                            addNew();
                                        }
                                    }}
                                    title={`${
                                        availableDependencies.length > 0 &&
                                        count < MAX_DEPENDENCIES_AMOUNT &&
                                        count < availableDependencies.length
                                            ? 'Add new dependency-offset pair'
                                            : `Maximum amount is ${
                                                  availableDependencies.length <
                                                  MAX_DEPENDENCIES_AMOUNT
                                                      ? availableDependencies.length <
                                                        2
                                                          ? 1
                                                          : availableDependencies.length
                                                      : MAX_DEPENDENCIES_AMOUNT
                                              }`
                                    }`}
                                    size="lg"
                                    icon={faPlus}
                                />
                                <FontAwesomeIcon
                                    className={`cursor-pointer ${
                                        count > 1
                                            ? 'delete-button'
                                            : 'text-muted'
                                    }`}
                                    onClick={() => {
                                        if (count > 1) remove();
                                    }}
                                    title={`${
                                        count > 1
                                            ? 'Remove dependency-offset pair'
                                            : 'At least one empty or filled pair must remain'
                                    }`}
                                    size="lg"
                                    icon={faTrashCan}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const renderTimelineEditor = () => {
        return (
            <Fragment>
                <div className="cgm-admin-page timeline-editor">
                    <div>
                        <div className="main-title-content">
                            <div className="title-breadcrumb">
                                <div className="page-title">
                                    <h3>Timeline Editor</h3>
                                </div>
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        <HomeButton />
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <span
                                            className="breadcrumb-link"
                                            onClick={() => {
                                                navigate(`/marketplace/admin`);
                                            }}>
                                            Marketplace Admin Home
                                        </span>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        Timeline Template
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>

                    <Card className="card-section main-card mb-3">
                        <CardBody>
                            {previewIsVisible ? (
                                <>
                                    <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{
                                            margin: '0 20px',
                                        }}>
                                        <div>
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                }}>
                                                {timelineName}
                                            </h5>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                setPreviewIsVisible(false)
                                            }>
                                            Back
                                        </button>
                                    </div>
                                    <MarketplaceTemplatePreviewComponent
                                        events={
                                            events
                                        }></MarketplaceTemplatePreviewComponent>
                                </>
                            ) : (
                                <>
                                    <div className="w-100 d-flex flex-column flex-lg-row justify-content-between align-items-center">
                                        <div className="d-flex w-100 w-lg-50 justify-content-between">
                                            <EditableName
                                                value={timelineName}
                                                viewContent={
                                                    <div
                                                        className={`text-bold w-50 mb-2 mb-lg-0 d-flex align-items-center ${
                                                            !nameIsValid
                                                                ? 'text-danger'
                                                                : ''
                                                        }`}>
                                                        {timelineName}
                                                        {!nameIsValid && (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faExclamationCircle
                                                                }
                                                                className="ml-2 text-danger"
                                                                title="The name is not valid. Please choose another name."
                                                            />
                                                        )}
                                                    </div>
                                                }
                                                updateValue={(
                                                    newName: string
                                                ) => doesNameExist(newName)}
                                                defaultEditMode={nameIsEditable}
                                                onEditModeChange={(isEditing) =>
                                                    setNameIsEditable(isEditing)
                                                }
                                                nameIsValid={nameIsValid}
                                            />

                                            <div className="text-bold d-flex justify-content-end align-items-center w-100 mb-2 mb-lg-0">
                                                Cumulative days:{' '}
                                                {events[events.length - 1]
                                                    ?.dayEnd || 0}
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end gap-2 w-100 w-lg-50 buttons-container">
                                            <button
                                                disabled={isLoading}
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setPreviewIsVisible(true);
                                                    setSelectedStage(0);
                                                }}>
                                                Preview
                                            </button>
                                            <button
                                                disabled={isLoading}
                                                className="btn btn-cancel ml-2"
                                                onClick={() => {
                                                    navigate(
                                                        `/marketplace/admin`
                                                    );
                                                }}>
                                                Cancel
                                            </button>
                                            <ButtonLoader
                                                buttonText={'Save'}
                                                loaderButtonText={''}
                                                disabled={
                                                    !isSaveEnabled || isLoading
                                                }
                                                isLoading={isLoading}
                                                onClick={handleSave}
                                                className={
                                                    'btn btn-primary ml-2'
                                                }
                                            />
                                        </div>
                                    </div>

                                    <hr />

                                    <div>
                                        <Form
                                            onSubmit={() => {}}
                                            initialValues={initialFormValues}
                                            validationSchema={validationSchema}>
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleSubmit,
                                                handleChange,
                                                resetForm,
                                                isValid,
                                                dirty,
                                            }) => (
                                                <>
                                                    <Row>
                                                        <Col md={3}>
                                                            <label>
                                                                Event Name
                                                            </label>
                                                            <Field
                                                                className="input-height"
                                                                type="text"
                                                                name="eventName"
                                                                id="eventName"
                                                                placeholder="Event Name"
                                                            />
                                                        </Col>

                                                        <Col md={3}>
                                                            <div className="d-flex justify-content-between ">
                                                                <div>
                                                                    <label>
                                                                        Stage
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex mt-1 mr-1">
                                                                    <FontAwesomeIcon
                                                                        className="cursor-pointer"
                                                                        onClick={() =>
                                                                            setIsStageEditorModalVisible(
                                                                                true
                                                                            )
                                                                        }
                                                                        icon={
                                                                            faGear
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <Input
                                                                    className="input-height"
                                                                    type="select"
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const selectedValue =
                                                                            parseInt(
                                                                                event
                                                                                    ?.target
                                                                                    ?.value
                                                                            );
                                                                        setSelectedStage(
                                                                            selectedValue
                                                                        );
                                                                    }}
                                                                    label="Stage"
                                                                    name="stage"
                                                                    id="stage"
                                                                    disabled={
                                                                        stagesAreLoading
                                                                    }
                                                                    placeholder="Select">
                                                                    {[
                                                                        {
                                                                            id: '',
                                                                            name: 'Select',
                                                                        },
                                                                        ...stages,
                                                                    ].map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    'practiceFocus' +
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </Input>
                                                            </div>
                                                        </Col>

                                                        <Col md={3}>
                                                            <label>Party</label>
                                                            <div className="form-group">
                                                                <Input
                                                                    className="input-height"
                                                                    type="select"
                                                                    label="Party"
                                                                    name="party"
                                                                    id="pary"
                                                                    placeholder="Seller">
                                                                    {[
                                                                        {
                                                                            id: '',
                                                                            name: 'Select',
                                                                        },
                                                                        ...PARTY_ITEMS,
                                                                    ].map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    'practiceFocus' +
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <label>
                                                                Duration
                                                            </label>
                                                            <Field
                                                                className="input-height"
                                                                type="number"
                                                                name="duration"
                                                                id="duration"
                                                                placeholder="0"
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <FieldArray name="dependencies">
                                                                {({
                                                                    remove,
                                                                    push,
                                                                }) =>
                                                                    values.dependencies.map(
                                                                        (
                                                                            dependency,
                                                                            index
                                                                        ) => {
                                                                            return renderDependencyRow(
                                                                                dependency,
                                                                                values.dependencies ??
                                                                                    [],
                                                                                index,
                                                                                values
                                                                                    .dependencies
                                                                                    .length,
                                                                                () =>
                                                                                    remove(
                                                                                        index
                                                                                    ),
                                                                                () =>
                                                                                    push(
                                                                                        {
                                                                                            dependencyEventId:
                                                                                                '',
                                                                                            offsetDays: 0,
                                                                                        }
                                                                                    )
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            </FieldArray>
                                                        </Col>

                                                        <Col md={3} lg={4} />

                                                        <Col md={3} lg={2}>
                                                            <label className="invisible hide-on-small">
                                                                text
                                                            </label>
                                                            <div className="button-container">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary input-height add-event-btn"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        handleSubmit();
                                                                        if (
                                                                            isValid &&
                                                                            dirty
                                                                        ) {
                                                                            const selectedStageId =
                                                                                Number(
                                                                                    values.stage
                                                                                );

                                                                            const stageExists =
                                                                                stages.some(
                                                                                    (
                                                                                        stage
                                                                                    ) =>
                                                                                        stage.id ===
                                                                                        Number(
                                                                                            values.stage
                                                                                        )
                                                                                );

                                                                            if (
                                                                                stageExists
                                                                            ) {
                                                                                handleAddRow(
                                                                                    {
                                                                                        stage: selectedStageId,
                                                                                        name:
                                                                                            values.eventName ||
                                                                                            'Default Event Name',
                                                                                        party: Number(
                                                                                            values.party
                                                                                        ),
                                                                                        duration:
                                                                                            Number(
                                                                                                values.duration
                                                                                            ),
                                                                                        dependencies:
                                                                                            values.dependencies,
                                                                                    }
                                                                                );
                                                                                resetForm(
                                                                                    {
                                                                                        values: {
                                                                                            eventName:
                                                                                                '',
                                                                                            stage: '',
                                                                                            party: '',
                                                                                            duration:
                                                                                                '',
                                                                                            dependencies:
                                                                                                [
                                                                                                    {
                                                                                                        dependencyEventId:
                                                                                                            '',
                                                                                                        offsetDays: 0,
                                                                                                    },
                                                                                                ],
                                                                                        },
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                createNotification(
                                                                                    'Selected stage does not exist in the list of stages',
                                                                                    'error'
                                                                                );
                                                                            }
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        !isValid ||
                                                                        !dirty
                                                                    }>
                                                                    Add Event
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Form>
                                    </div>

                                    <hr />

                                    <div>
                                        {!initialDataLoading ? (
                                            <TimelineTable
                                                rows={events}
                                                stages={stages}
                                                handleRemoveRow={
                                                    handleRemoveRow
                                                }
                                                handleUpdateRow={
                                                    handleUpdateRow
                                                }
                                                isLoading={stagesAreLoading}
                                            />
                                        ) : (
                                            renderLoader()
                                        )}
                                    </div>

                                    <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
                                        <div className="d-flex justify-content-end gap-2 w-100 w-md-auto buttons-container">
                                            <button
                                                disabled={isLoading}
                                                className="btn btn-cancel ml-2"
                                                onClick={() => {
                                                    navigate(
                                                        `/marketplace/admin`
                                                    );
                                                }}>
                                                Cancel
                                            </button>
                                            <ButtonLoader
                                                buttonText={'Save'}
                                                loaderButtonText={''}
                                                disabled={
                                                    !isSaveEnabled || isLoading
                                                }
                                                isLoading={isLoading}
                                                onClick={handleSave}
                                                className={
                                                    'btn btn-primary ml-2'
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </CardBody>
                    </Card>

                    <StageEditorModal
                        stagesUsedLocally={Array.from(
                            new Set([
                                ...events.map((event) => event.stage),
                                selectedStage,
                            ])
                        )}
                        isVisible={isStageEditorModalVisible}
                        onClose={() => setIsStageEditorModalVisible(false)}
                        setFlagToFetch={handleFlagState}
                    />
                </div>
            </Fragment>
        );
    };

    return (
        <Layout>
            {isAdmin ? (
                renderTimelineEditor()
            ) : (
                <h1>You don't have an access to see this page.</h1>
            )}
        </Layout>
    );
};

export default TimelineEditor;
