import { createAsyncThunk } from "@reduxjs/toolkit";
import { TimelineStage } from "../models/timeline-stage.model"; 

export const GetTimelineStages = createAsyncThunk(
    'timelineTemplates/getTimelineStages',
    async (_, dispatch: any) => {
        const axios = dispatch.getState().core.axios;
        try {
            const response = await axios.get('api/Marketplace/TimelineStages');
            const filteredStages = response.data.map((stage: TimelineStage) => ({
                id: stage.id,
                name: stage.name,
                isInUse: stage.isInUse,
            }));
            return filteredStages;
        } catch (error: any) {
            return error;
        }
    }
);
