import core from '../reducers/core-reducer';
import clubhouseMessagesSlice from '../clubhouse/slices/messages/messages-slice';
import clubhouseSlice from '../clubhouse/slices/clubhouse/clubhouse-slice';
import currentUserSlice from '../slices/current-user/current-user-slice';
import loggerMiddleware from '../middleware/logger';
import directRoomsSlice from '../direct/slices/rooms/direct-rooms-slice';
import directMembersSlice from '../direct/slices/members/direct-members-slice';
import directMessagesSlice from '../direct/slices/messages/direct-messages-slice';
import pricingSlice from '../slices/pricing/pricing-slice';
import userSummarySlice from '../slices/user-summary/user-summary-slice';
import areYouStillHereSlice from '../are-you-still-here/are-you-still-here-slice/are-you-still-here-slice';
import supportRequestsSlice from '../support-portal/slices/threads/support-threads-slice';
import supportThreadsMessagesSlice from '../support-portal/slices/messages/support-threads-messages-slice';
import supportPortalMembersSlice from '../support-portal/slices/members/support-portal-members-slice';
import dataRequestNotificationsSlice from '../models/DataRequestHub/slices/data-request-notifications.slice';
import notificationFlagsSlice from '../slices/notification-flags/notification-flags.slice';
import timelinesSlice from '../slices/marketplace/timeline.slice';
import { configureStore } from '@reduxjs/toolkit';
import ThemeOptionsSlice from '../reducers/ThemeOptionsSlice';

export default function configureReduxStore() {
    const store = configureStore({
        reducer: {
            core,
            ThemeOptions: ThemeOptionsSlice,
            clubhouseMessages: clubhouseMessagesSlice,
            clubhouse: clubhouseSlice,
            currentUser: currentUserSlice,
            directRooms: directRoomsSlice,
            directMembers: directMembersSlice,
            directMessages: directMessagesSlice,
            pricing: pricingSlice,
            userSummary: userSummarySlice,
            supportThreads: supportRequestsSlice,
            supportThreadsMessages: supportThreadsMessagesSlice,
            supportPortalMembers: supportPortalMembersSlice,
            areYouStillHere: areYouStillHereSlice,
            dataRequestsNotifications: dataRequestNotificationsSlice,
            notificationFlags: notificationFlagsSlice,
            timelines: timelinesSlice,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }).concat(
                process.env.NODE_ENV === 'development' ? [loggerMiddleware] : []
            ),
        devTools: process.env.NODE_ENV !== 'production',
    });

    return store;
}
